




import React from "react";
import { Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

import Layout from "../components/Layout";
import { Box, Button, Container, Grid, Paper, Stack, Typography } from "@mui/material";
import Debug from "../components/Debug";
import useSiteMetadata from "../components/SiteMetadata";
import Markdown from "../components/Markdown";
import ButtonBackToMainPage from "../components/ButtonBackToMainPage";

const getData = ({ email, companyFullName, serviceName, companyShortestName, ceo, website, address, name, nip, regon }) => (`
    
# POLITYKA PRYWATNOŚCI DANYCH OSOBOWYCH I PLIKÓW COOKIES SERWISU  ${serviceName}

**Obowiązuje od: 20.10.2024 r.**

## § 1. Tożsamość administratora danych

1. Administratorem danych osobowych podawanych w trakcie korzystania z Serwisu prowadzonego pod nazwą ${serviceName} jest ${companyFullName}. Dane kontaktowe Administratora: ${address} 

2. Dane przetwarzane są zgodnie z aktualnie obowiązującymi przepisami prawa; tj. Rozporządzeniem Parlamentu Europejskiego i Rady UE 2016/679 z 27 kwietnia 2016 r. w sprawie ochrony osób fizycznych w związku z przetwarzaniem danych osobowych i w sprawie swobodnego przepływu takich danych oraz uchylenia dyrektywy 95/46/WE (dalej: RODO), ustawą z dnia 10 maja 2018 r. o ochronie danych, a także ustawą z 18 lipca 2002 r. o świadczeniu usług drogą elektroniczną.

## § 2. Stosowane definicje

1. W niniejszej polityce stosuje się następujące definicje:
    * **Serwis** - serwis internetowy dostępny pod adresem ${website} za pośrednictwem którego Użytkownik może: przeglądać jego zawartość i kontaktować się z administratorem danych (formularz kontaktowy).
    * **Administrator danych osobowych** - podmiot, który decyduje o celu i środkach przetwarzania danych, w niniejszej polityce rozumie się przez to: ${companyFullName}.
    * **Użytkownik** - osoba fizyczna, której dane dotyczą i która korzysta z usług dostępnych w Serwisie.
    * **Dane osobowe** - wszelkie informacje, które bez nadmiernego czasu i kosztu mogą doprowadzić do identyfikacji osoby fizycznej, w tym jej dane identyfikacyjne, adresowe i kontaktowe.

## § 3. Cele przetwarzania danych

1. Administrator danych osobowych przetwarza dane osobowe w następujących celach:
    1. udzielenia odpowiedzi na zadane pytanie poprzez formularz kontaktowy lub za pośrednictwem danych teleadresowych dostępnych na stronie Serwisu (art. 6 ust. 1 lit. f RODO)
    2. jeżeli przekazujesz nam informacje o stanie zdrowia Twoim lub Twoich bliskich, przetwarzanie danych odbywa się w celu dopasowania usług świadczonych przez ${companyShortestName}, tak aby jak najlepiej spełnić Twoje oczekiwania i potrzeby (art. 6 list a i f oraz art. 9 ust. 2 lit a RODO),
    3. dochodzenia praw i roszczeń przez Administratora danych lub osobę, której dane dotyczą (art. 6 ust. 1 lit. f RODO)
2. **Podanie danych jest dobrowolne, ale niezbędne do udzielenia odpowiedzi na pytania zadane poprzez formularz kontaktowy, ewentualnego do dochodzenia jakichkolwiek roszczeń.**


## § 4. Sposoby pozyskiwania danych



1. Twoje dane osobowe w większości przypadków przetwarzamy, ponieważ sam nam je przekazujesz poprzez: 
    * wypełnienie formularza z danymi kontaktowymi podczas składania zapytania przez formularz na stronie,
    * bezpośredni kontakt z Administratorem danych za pomocą danych teleadresowych dostępnych na stronie lub w formie tradycyjnej w miejscu prowadzenia działalności.


## § 5. Zakres przetwarzanych danych

1. Zakres przetwarzanych danych osobowych został ograniczony do minimum niezbędnego do świadczenia usług w zakresie. W zależności od celu, możemy przetwarzać następujące informacje na Twój temat: 
    * imię i nazwisko, 
    * adres zamieszkania, 
    * adres wykonywania działalności gospodarczej, 
    * numer NIP, 
    * adres e-mail, 
    * numer telefonu, 
    * dane zawarte w korespondencji e-mail, 
    * adres IP.

2. Jeżeli dołączysz do wiadomości w formularzu kontaktowym lub e-mail lub też przekażesz w rozmowie telefonicznej jakiekolwiek informacje o stanie zdrowia Twoim lub Twoich bliskich – w formie opisu lub załączników do tej wiadomości – dane te będą przetwarzane w zakresie niezbędnym do dopasowania usług świadczonych przez ${companyShortestName}, tak aby jak najlepiej spełnić Twoje oczekiwania i potrzeby.


## § 6. Okres przetwarzania danych



1. Dane osobowe są przetwarzane przez okres niezbędny do realizacji celu, dla którego zostały zebrane, tj.: 
    * przez czas niezbędny do zakończenia komunikacji rozpoczętej przez Ciebie za pośrednictwem formularza kontaktowego, naszego adresu e-mail lub drogą telefoniczną.  Po tym okresie dane mogą zostać usunięte, ale mogą również podlegać archiwizacji, jeżeli przebieg komunikacji uzasadnia takową archiwizację, np. z uwagi na potrzebę wykazania tego przebiegu w przyszłości. W tym zakresie nie jesteśmy w stanie określić jednoznacznego terminu usunięcia danych.
    * do czasu odwołania zgody, jeśli przetwarzanie danych opiera się na zgodzie osoby, której dane dotyczą.


## § 7. Odbiorcy danych



1. Dane osobowe Użytkownika mogą zostać powierzone innym podmiotom w celu wykonania usług na zlecenie Administratora danych, w szczególności podmiotom w zakresie: 
    * hostingu strony www, 
    * serwisu i utrzymania systemów informatycznych, w których dane są przetwarzane,
    * prowadzenia obsługi księgowej i prawnej,
    * prowadzenia obsługi biurowej.
2. Twoje dane osobowe nie są przekazywane do państw trzecich, ani organizacji międzynarodowych. 
3. Dane osobowe przechowywane są na serwerach zlokalizowanych na terenie Europejskiego Obszaru Gospodarczego i nie dochodzi w tym zakresie do ich przekazywania do żadnego państwa trzeciego ani organizacji międzynarodowej. Jednak, jeżeli serwery wykorzystywanej przez Twoją pocztę e-mail zlokalizowane są poza Europejskim Obszarem Gospodarczym, zarówno wysyłane, jak i odbierane przez Ciebie wiadomości, przechowywane są na tymże serwerze. W związku z tym, dobrze jest upewnić się czy Twoja poczta e-mail korzysta z serwerów zlokalizowanych w obrębie Europejskiego Obszaru Gospodarczego.


## § 8. Prawa osób, których dane dotyczą



1. Osobie, której dane dotyczą przysługuje:
    * prawo dostępu do treści danych i ich sprostowania,
    * prawo do usunięcia danych, o ile nie obowiązują inne przepisy prawa, które zobowiązują administratora danych do archiwizacji danych przez określony czas, 
    * prawo do przenoszenia danych, o ile podstawą przetwarzania danych jest umowa lub zgoda osoby, której dane dotyczą, a przetwarzanie danych odbywa się w sposób automatyczny,
    * prawo do wniesienia sprzeciwu na przetwarzanie danych w celach marketingu bezpośredniego, realizowanego przez administratora danych w ramach usprawiedliwionego interesu prawnego, a także ograniczenia przetwarzania,
    * prawo do niepodlegania automatycznemu profilowaniu, jeśli administrator danych podejmowałby decyzje opierające się wyłącznie na automatycznym profilowaniu i niosące ze sobą skutki prawne dla osoby, której dane dotyczą lub podobnie na nią wpływały,
    * prawo do kontroli przetwarzania danych i informacji na temat tego, kto jest administratorem danych, a także uzyskania informacji o celu, zakresie i sposobie przetwarzania danych, treści tych danych, źródle danych, a także sposobie udostępniania, w tym o odbiorcach lub kategoriach odbiorców danych,
    * prawo do cofnięcia zgody w dowolnym momencie, jeśli podstawą przetwarzania danych była zgoda osoby, której dane dotyczą. Odwołanie zgody nie wpływa na zgodność z prawem przetwarzania dokonanego na jej podstawie, przed jej cofnięciem,
    * prawo do wniesienia skargi do Prezesa Urzędu Ochrony Danych Osobowych (PUODO), jeśli osoba uzna, że przetwarzanie jej danych jest niezgodne z aktualnie obowiązującymi przepisami w tym zakresie.
2. Zasady związane z realizacją wskazanych powyżej uprawnień zostały opisane szczegółowo w art. 16 – 21 RODO. Zachęcamy do zapoznania się z tymi przepisami. Wskazane powyżej uprawnienia nie są bezwzględne i nie będą przysługiwać Ci w stosunku do wszystkich czynności przetwarzania Twoich danych osobowych. Zawsze jednak przysługuje Ci skarga do PUODO oraz możesz zwrócić się do nas z żądaniem udostępnienia Ci informacji o tym, jakie dane na Twój temat posiadamy oraz w jakich celach je przetwarzamy. W tym celu wyślij wiadomość na adres: ${address}


## § 9. Bezpieczeństwo danych osobowych

Administrator dba o bezpieczeństwo Twoich danych osobowych. Na bieżąco analizuje ryzyka, jakie wiążą się z poszczególnymi procesami przetwarzania Twoich danych, a następnie wdraża odpowiednie środki bezpieczeństwa i ochrony danych osobowych. Monitoruje stan infrastruktury technicznej, szkoli personel, aktualizuje stosowne procedury, wprowadza konieczne usprawnienia. W razie jakichkolwiek pytań dotyczących Twoich danych osobowych możesz skontaktować się pod adresem: ${address}. Prosimy także byś upewnił się czy regulamin poczty e-mail, z której korzystasz, nie uprawnia dostawcy poczty do skanowania treści wysyłanych i odbieranych wiadomości, w szczególności w celach marketingowych, co występuje najczęściej w przypadku nieodpłatnych usług poczty e-mail. W takiej sytuacji, poufność wymienianych wiadomości jest zagrożona, co nie zależy jednak od Administratora Twoich danych.


## § 10. Pliki cookies



1. Strona internetowa ${website}, podobnie jak niemal wszystkie inne strony internetowe, wykorzystuje pliki cookies.
2. Cookies to niewielkie informacje tekstowe, przechowywane na Twoim urządzeniu końcowym (np. komputerze, tablecie, smartfonie), które mogą być odczytywane przez mój system teleinformatyczny (cookies własne) lub systemy teleinformatyczne podmiotów trzecich (cookies podmiotów trzecich). W plikach cookies mogą być zapisywane i przechowywane określone informacje, do których następnie systemy teleinformatyczne mogą uzyskiwać dostęp w określonych celach.
3. Niektóre używane przez nas cookies są usuwane po zakończeniu sesji przeglądarki internetowej, tzn. po jej zamknięciu (tzw. cookies sesyjne). Inne cookies są zachowywane na Twoim urządzeniu końcowym i umożliwiają rozpoznanie Twojej przeglądarki przy kolejnym wejściu na stronę (trwałe cookies).
4. Z plików cookies korzystamy na podstawie Twojej zgody, za wyjątkiem sytuacji, gdy pliki cookies są niezbędne do prawidłowego świadczenia na Twoją rzecz usługi drogą elektroniczną.
5. Pliki cookies, które nie są niezbędne do prawidłowego świadczenia usługi drogą elektroniczną, pozostają zablokowane do czasu wyrażenia przez Ciebie zgody na korzystanie z plików cookies. Podczas Twojej pierwszej wizyty na naszej stronie wyświetlamy Ci komunikat z pytaniem o Twoją zgodę wraz z możliwością zarządzania plikami cookies, tj. zdecydowania, na które pliki cookies się godzisz, a które chcesz blokować.
6. Pamiętaj, że wyłączenie lub ograniczenie obsługi plików cookies może uniemożliwiać używanie części funkcji dostępnych na mojej stronie internetowej oraz powodować trudności w korzystaniu ze strony, jak również z wielu innych stron internetowych, które stosują cookies.
7. Pliki cookies własne wykorzystywane są do zapewnienia prawidłowego funkcjonowania poszczególnych mechanizmów strony, takich jak wysłanie wiadomości poprzez formularz kontaktowy.
8. W plikach cookies własnych przechowywana jest również informacja o zdefiniowanych przez Ciebie ustawieniach cookies dokonanych z poziomu mechanizmu do zarządzania plikami cookies.


## § 11. Postanowienia końcowe



1. Administrator danych zastrzega możliwość zmiany niniejszej polityki prywatności, szczególnie w sytuacji, gdy będą tego wymagały zastosowane rozwiązania techniczne, zmiany przepisów prawa w zakresie prywatności osób, których dane dotyczą lub inne, istotne zmiany wpływające na treść informacji przekazywanej Użytkownikom.
2. W przypadku zmiany obowiązującej polityki prywatności, wprowadzone zostaną odpowiednie modyfikacje do powyższego zapisu, które będą obowiązywały od dnia ich opublikowania na stronie Serwisu.
 
        `)


export const IndexPage = ({

}) => {


    const siteMeta = useSiteMetadata();


    return (
        <Layout variant="regulamin">

            <Container maxWidth="sm" sx={{

                position: "relative"
            }}>
                <ButtonBackToMainPage />

                {/* <Typography variant="h1">Polityka prywatności</Typography> */}


                <Markdown>{getData(siteMeta)}</Markdown>


            </Container>
        </Layout>

    );
};




export default IndexPage;

export const Head = () => {

    const { tel,
        linkgooglemap,
        address,
        title,
        description,
        siteUrl
    } = useSiteMetadata();

    return (<>
        {/* https://www.searchenginejournal.com/important-tags-seo/156440/ */}
        <title>{title}</title>
        <meta name="description" content={description} />
        {/* <meta name="robots" content="nofollow"> */}

        {/* <link rel="canonical" href={siteUrl} /> */}

        <meta property="og:title" content={title} />

        <meta property="og:description" content={description} />

        <meta property="og:image" content={`${siteUrl}/img/map.jpg`} />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        {/* <meta charset="UTF-8" /> */}


    </>


    )
}


